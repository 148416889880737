import { createApp } from 'vue'
import router from '@/login/router'
import store from './store'
import LoginApp from '@/login/LoginApp.vue'
import baseAlert from '@/components/alert/base-alert-modal'
import BaseForm from '@/components/inputs/BaseForm'
import BaseInput from '@/components/inputs/BaseInput'
import BaseButton from '@/components/buttons/BaseButton'
import BaseButtonSubmit from '@/components/buttons/BaseButtonSubmit.vue'
import vuetify from '@/plugins/vuetify'

const app = createApp({
  components: { LoginApp },
  template: '<LoginApp/>'
})

function loginBaseAlert (options) {
  return baseAlert(options, router)
}

app.config.globalProperties.$baseAlert = loginBaseAlert

app.component('BaseForm', BaseForm)
app.component('BaseInput', BaseInput)
app.component('BaseButton', BaseButton)
app.component('BaseButtonSubmit', BaseButtonSubmit)

app.use(store)
app.use(router)
app.use(vuetify)

app.mount('#login')

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}
