<template>
  <v-app>
    <LoginPage />
  </v-app>
</template>

<script>
import LoginPage from '@/login/components/LoginPage'

export default {
  name: 'LoginApp',
  components: { LoginPage }
}
</script>

<style lang="scss">
@import "@/assets/scss/default.scss";
@import "@/assets/scss/_variables.scss";

/* Customize existing libraries' css to fit our styling requirements */
@import "@/assets/scss/custom-vuetify.scss";

@include font-face("@/assets/font");

body {
  background-color: $background-color;
}

#login {
  font-family: "Open Sans", sans-serif;
  color: $neutral-typography-dark;
  letter-spacing: 0.2px;
}
</style>
