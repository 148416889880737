import { convertToUtc } from '@/residency/utils/residency-date-util'
import store from '@/residency/store'
import userRoles from '@/residency/consts/user-roles'
import notificationTypes from '@/residency/views/schedule/notification/notification-types'

export function isCardNotification (type) {
  // Only residents can recieve notification cards for new assessments
  return (type !== notificationTypes.NEW_ASMT || store.getters.me.role === userRoles.RESIDENT)
}

export function parseEventDeletedNotification (notification) {
  notification.creationDate = new Date(notification.creationDate)
  if (notification.eventStartDate) {
    notification.eventStartDate = convertToUtc(notification.eventStartDate)
  }
  notification.title = 'An event has been cancelled.'
  notification.description = `"${notification.eventTitle}" has been cancelled and removed from your upcoming Simsei events.`
  notification.timeAgo = calculateTimePast(notification.creationDate)
  return notification
}

/**
 * @param date object
 * @returns eg: '30 seconds ago'
 */
export function calculateTimePast (date) {
  let timeDiff = Math.round((new Date() - date) / 1000)
  const checkPlural = (num) => {
    return num === 1 ? '' : 's'
  }

  if (timeDiff < 60) {
    // Created less than a minute ago
    return 'Just now'
  } else if (timeDiff < 3600) {
    // Created less than an hour ago
    timeDiff = Math.floor(timeDiff / 60)
    return `${timeDiff} minute${checkPlural(timeDiff)} ago`
  } else if (timeDiff < 86400) {
    // Created less than a day ago
    timeDiff = Math.floor(timeDiff / 3600)
    return `${timeDiff} hour${checkPlural(timeDiff)} ago`
  } else {
    // Created over a day ago
    timeDiff = Math.floor(timeDiff / 86400)
    return `${timeDiff} day${checkPlural(timeDiff)} ago`
  }
}

export function getNotificationTypeByAsmtId (asmtId) {
  const notifId = store.getters.asmtIdToNotifId[asmtId]
  return store.getters.notifications[notifId]?.type
}

export function deleteAsmtNotification (asmtId) {
  // Check if the asmt has a corresponding asmt notification, if so delete it
  const notifId = store.getters.asmtIdToNotifId[asmtId]
  if (notifId && store.getters.notifications[notifId]) {
    store.dispatch('deleteNotification', store.getters.notifications[notifId])
  }
}
